import dragonfliesSurvey from './dragonflies';
import bryophytesSurvey from './bryophytes';
import butterfliesSurvey from './butterflies';
import plantFungiSurvey from './plantFungi';
import birdsSurvey from './birds';

export default {
  [dragonfliesSurvey.name]: dragonfliesSurvey,
  [bryophytesSurvey.name]: bryophytesSurvey,
  [butterfliesSurvey.name]: butterfliesSurvey,
  [plantFungiSurvey.name]: plantFungiSurvey,
  [birdsSurvey.name]: birdsSurvey,
};
